
import { mapGetters } from 'vuex'
import { Swiper as SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'

SwiperCore.use([Navigation, Pagination])

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    mixins: [cms, playGames],
    computed: {
        ...mapGetters({
            featuredContent2Getter: 'cms/featuredContent2',
        }),
        featuredContent2() {
            return this.featuredContent2Getter(this.$config.THEME)
        },
    },
    methods: {
        onNext() {
            const swiperInstance = this.$refs.featuredContent2Swiper.swiperRef
            swiperInstance.slideNext()
        },
    },
}
